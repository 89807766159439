import * as React from "react"
import Layout from "hoc/Layout"
import Button from "components/Buttons/Button"
import { Grid } from "@mui/material"
import Font56 from "components/Fonts/Font56"
import Font32 from "components/Fonts/Font32"
import Font24 from "components/Fonts/Font24"

const NotFoundPage = () => (
  <Layout seoTitle="404: Website existiert nicht">
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      py={10}
    >
      <Font56 style={{ fontSize: "78px" }}>404</Font56>
      <Font32 mb={4}>Ups…Die gesuchte Website existiert nicht!</Font32>
      <Font24 fw={400} mb={4}>
        Gehen Sie zurück zur Startseite!
      </Font24>
      <Button to="/">Startseite</Button>
    </Grid>
  </Layout>
)

export default NotFoundPage
